<template>
  <step-lesson-layout class="module1-part3-lesson2" :title="$t('module1.part3.lesson2.title')" :title-annotation="$t('module1.part3.lesson2.titleAnnotation')">
    <template v-slot:content>
      <!-- BLOCK 1 -->
      <div class="image-tabs-wrapper mpr-tabs-wrapper">
        <app-tabs :tabs="mprTabs">
          <template v-slot:tab="{ name, image, isCurrent }">
            <div class="image-tab mpr-tab" :class="{ current: isCurrent }">
              <img :src="image" :alt="name">
            </div>
          </template>
          <template v-slot:content="{ name }">
            <div class="tab-content image-tab-content" v-if="name === 'etat'">
              <p class="title">{{ $t('module1.part3.lesson2.block1.tab1.title') }}</p>
              <p class="annotation">{{ $t('module1.part3.lesson2.block1.tab1.annotation') }}</p>
              <p class="content" v-html="$t('module1.part3.lesson2.block1.tab1.content')"></p>
            </div>
            <div class="tab-content image-tab-content" v-else-if="name === 'anah'">
              <p class="title">{{ $t('module1.part3.lesson2.block1.tab2.title') }}</p>
              <p class="annotation">{{ $t('module1.part3.lesson2.block1.tab2.annotation') }}</p>
              <p class="content" v-html="$t('module1.part3.lesson2.block1.tab2.content')"></p>
            </div>
            <div class="tab-content image-tab-content" v-else-if="name === 'part'">
              <p class="title">{{ $t('module1.part3.lesson2.block1.tab3.title') }}</p>
              <p class="annotation">{{ $t('module1.part3.lesson2.block1.tab3.annotation') }}</p>
              <p class="content" v-html="$t('module1.part3.lesson2.block1.tab3.content')"></p>
              <div class="app-text-drawer">
                <drawer>
                  <template v-slot:head="{ isOpen }">
                    <div class="app-text-drawer-head" :class="{ open: isOpen }">
                      {{ $t('module1.part3.lesson2.block1.tab3.drawer.title') }}
                    </div>
                  </template>
                  <template v-slot:content>
                    <div class="app-text-drawer-content">
                      <p v-html="$t('module1.part3.lesson2.block1.tab3.drawer.content')"></p>
                    </div>
                  </template>
                </drawer>
              </div>
            </div>
            <div class="tab-content image-tab-content" v-else-if="name === 'pro'">
              <p class="title">{{ $t('module1.part3.lesson2.block1.tab4.title') }}</p>
              <p class="annotation">{{ $t('module1.part3.lesson2.block1.tab4.annotation') }}</p>
              <p class="content" v-html="$t('module1.part3.lesson2.block1.tab4.content')"></p>
              <div class="app-text-drawer">
                <drawer>
                  <template v-slot:head="{ isOpen }">
                    <div class="app-text-drawer-head" :class="{ open: isOpen }">
                      {{ $t('module1.part3.lesson2.block1.tab4.drawer1.title') }}
                    </div>
                  </template>
                  <template v-slot:content>
                    <div class="app-text-drawer-content">
                      <p v-html="$t('module1.part3.lesson2.block1.tab4.drawer1.content')"></p>
                    </div>
                  </template>
                </drawer>
              </div>
            </div>
            <div class="tab-content image-tab-content" v-else-if="name === 'mar'">
              <p class="title">{{ $t('module1.part3.lesson2.block1.tab5.title') }}</p>
              <p class="annotation">{{ $t('module1.part3.lesson2.block1.tab5.annotation') }}</p>
              <p class="content" v-html="$t('module1.part3.lesson2.block1.tab5.content')"></p>
              <div class="app-text-drawer">
                <drawer>
                  <template v-slot:head="{ isOpen }">
                    <div class="app-text-drawer-head" :class="{ open: isOpen }">
                      {{ $t('module1.part3.lesson2.block1.tab5.drawer1.title') }}
                    </div>
                  </template>
                  <template v-slot:content>
                    <div class="app-text-drawer-content">
                      <p>{{ $t('module1.part3.lesson2.block1.tab5.drawer1.content') }}</p>
                      <p>{{ $t('module1.part3.lesson2.block1.tab5.drawer1.content2') }}</p>
                    </div>
                  </template>
                </drawer>
              </div>
            </div>
          </template>
        </app-tabs>
      </div>

      <!-- BLOCK 2 -->
      <div class="app-text-block">
        <p class="title">{{ $t('module1.part3.lesson2.block2.title') }}</p>
        <p class="paragraphe" v-html="$t('module1.part3.lesson2.block2.content')"></p>
      </div>

      <app-button-layout @click="$emit('next-tab')">
        {{ $t('global.navigation.resume') }}
      </app-button-layout>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import AppTabs from '@/components/AppTabs'
import Drawer from '@/components/Drawer'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'

export default {
  name: 'Module1Part3Lesson2',
  components: { AppButtonLayout, StepLessonLayout, AppTabs, Drawer },
  emits: ['next-tab'],
  data () {
    return {
      mprTabs: [
        {
          name: 'etat',
          image: require('@/assets/module1/part3/m1p3lesson2tab1.svg')
        },
        {
          name: 'anah',
          image: require('@/assets/module1/part3/m1p3lesson2tab2.svg')
        },
        {
          name: 'part',
          image: require('@/assets/module1/part3/m1p3lesson2tab3.svg')
        },
        {
          name: 'pro',
          image: require('@/assets/module1/part3/m1p3lesson2tab4.svg')
        },
        {
          name: 'mar',
          image: require('@/assets/module1/part3/m1p3lesson2tab5.svg')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.module1-part3-lesson2 {
  padding: 0 $space-s;
  .app-button-layout {
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
  }
  .mpr-tabs-wrapper {
    .tab-content {
      padding: $space-m $space-l;
      .title {
        color: $c-secondary;
        font-family: $ff-big;
        font-size: $fz-xl;
        font-weight: $fw-l;

      }
      .sub-title {
        margin-top: $space-xs;
        color: $c-secondary;
        font-family: $ff-big;
        font-size: $fz-ml;
        font-weight: $fw-l;
      }
      .annotation {
        margin-top: $space-xs;
        color: $c-text-dark;
        font-size: $fz-ml;
        font-weight: $fw-l;
      }
      .content {
        margin-top: $space-sm;
        color: $c-text-dark;
        font-family: $ff-text;
        font-weight: $fw-m;
      }
      .app-text-drawer {
        margin-top: $space-m;
      }
      .app-text-drawer + .app-text-drawer {
        margin-top: $space-s;
      }
    }
  }
}

@media (min-width: 420px) {
  .module1-part3-lesson2 {
    .mpr-tabs-wrapper {
      .mpr-tab {
        img {
          padding: 2px;
          width: auto;
          max-height: none;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.mpr-tabs-wrapper {
  .app-tabs {
    .tabs-wrapper .tabs {
      display: grid;
      gap: 2px;
      grid-template-columns: repeat(5, 1fr);
      .image-tab {
        &.mpr-tab {
          height: 100%;
        }
        &.current:after {
          transform: translateY(25px) scale(1.2);
          background-size: 14px;
        }
        &:after {
          font-size: clamp(1rem, 8vw, 3rem);
          width: 24px;
          height: 24px;
          left: calc(50% - 12px);
        }
      }
    }
  }
}

@media (min-width: $bp-phone) {
  .mpr-tabs-wrapper {
    .app-tabs {
      .tabs-wrapper .tabs {
        .image-tab {
          &.current:after {
            transform: translateY(32px);
            background-size: auto;
          }
          &:after {
            width: 40px;
            height: 40px;
            left: calc(50% - 20px);
          }
        }
      }
    }
  }
}
</style>
